import { Injectable } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';

declare var angular: angular.IAngularStatic;

@Injectable()
export class UuidService {
    private limitUI06: number;
    private limitUI08: number;
    private limitUI12: number;
    private limitUI16: number;
    private limitUI32: number;

    constructor() {
        this.limitUI06 = this.maxFromBits(6);
        this.limitUI08 = this.maxFromBits(8);
        this.limitUI12 = this.maxFromBits(12);
        this.limitUI16 = this.maxFromBits(16);
        this.limitUI32 = this.maxFromBits(32);
    }

    private maxFromBits(bits:number):number {
        return Math.pow(2, bits);
    };

    private getRandomInt(min:number, max:number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    private randomUI06() {
        return this.getRandomInt(0, this.limitUI06 - 1);
    };

    private randomUI08() {
        return this.getRandomInt(0, this.limitUI08 - 1);
    };

    private randomUI12() {
        return this.getRandomInt(0, this.limitUI12 - 1);
    };

    private randomUI16() {
        return this.getRandomInt(0, this.limitUI16 - 1);
    };

    private randomUI32() {
        return this.getRandomInt(0, this.limitUI32 - 1);
    };

    private randomUI48() {
        return (
            (0 | (Math.random() * (1 << 30))) +
            (0 | (Math.random() * (1 << (48 - 30)))) * (1 << 30)
        );
    };

    private paddedString(string:string, length:number) {
        string = String(string);
        let z = '0';
        let i = length - string.length;
        for (; i > 0; i >>>= 1, z += z) {
            if (i & 1) {
                string = z + string;
            }
        }
        return string;
    };

    private fromParts(
        timeLow:number,
        timeMid:number,
        timeHiAndVersion:number,
        clockSeqHiAndReserved:number,
        clockSeqLow:number,
        node:number
    ) {
        return this.paddedString(timeLow.toString(16), 8) +
            '-' +
            this.paddedString(timeMid.toString(16), 4) +
            '-' +
            this.paddedString(timeHiAndVersion.toString(16), 4) +
            '-' +
            this.paddedString(clockSeqHiAndReserved.toString(16), 2) +
            this.paddedString(clockSeqLow.toString(16), 2) +
            '-' +
            this.paddedString(node.toString(16), 12);

    };

    generate() {
        return this.fromParts(
            this.randomUI32(),
            this.randomUI16(),
            0x4000 | this.randomUI12(),
            0x80 | this.randomUI06(),
            this.randomUI08(),
            this.randomUI48()
        );
    };

    validate(uuid:string) {
        let testPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return testPattern.test(uuid);
    };
}

angular.module('comcast.common.communication').factory('UuidService', downgradeInjectable(UuidService));
