declare var angular: angular.IAngularStatic;

export class SubmitManager {
    static get $inject() {
        return [
            'LiteDeliveryResourceFactory',
            'UuidService',
            '$rootScope',
            '$q',
        ];
    }

    private LiteDeliveryResourceFactory:any;
    private uuid4:any;
    private $rootScope:any;
    private $q:any;

    private ACTIVE_UPLOADS:string = '__activeUploads';
    private ACTIVE_SUBMITS:string = '__activeSubmits';
    private runningJob:any = 0;
    private queue:any = [];

    constructor(
        LiteDeliveryResourceFactory:any,
        uuid4:any,
        $rootScope:any,
        $q:any,
    ) {
        Object.assign(this, {
            LiteDeliveryResourceFactory,
            uuid4,
            $rootScope,
            $q,
        });

        /* BINDABLE: DATA */
    }

    //START FUNCTION BLOCK
    next(jobUuid:any = undefined) {
        // Only the active job can unlock the process and allow the next job to run
        if (jobUuid !== this.runningJob) {
            return false;
        }

        // Get the next thing to do and set it loose on the world
        let nextJob = this.queue.shift();
        if (nextJob === undefined) {
            this.runningJob = 0;
        } else {
            this.runningJob = nextJob.uuid;
            nextJob.job.resolve(true);
        }
    };

    getActiveSubmits() {
        let newJob = this.$q.defer(),
            jobCompletion = this.$q.defer(),
            uuid = this.uuid4.generate();

        this.queue.push({
            uuid: uuid,
            job: newJob,
        });

        newJob.promise.then(() => {
            let activeSubmits;

            if (localStorage.getItem(this.ACTIVE_SUBMITS) === null) {
                activeSubmits = [];
            } else {
                // Attempt to parse the active submits, otherwise just assume they are nonexistant or empty
                try {
                    activeSubmits = JSON.parse(localStorage.getItem(this.ACTIVE_SUBMITS) || '');
                } catch (e) {
                    activeSubmits = [];
                }
            }

            if (activeSubmits.length > 0) {
                this.LiteDeliveryResourceFactory.getQuiet({ spotIds: activeSubmits },
                (spots:any) => {
                    spots = spots.data;

                    // Retrieve IDs for any active submit spot that is not in a terminal status
                    let submitIds:any = [];
                    let filteredSubmits = spots.rows.filter(function (spot:any) {
                        if (
                            ['SENT', 'CANCELLED', 'REJECTED'].indexOf(
                                spot.status.toUpperCase()
                            ) > -1 ||
                            (spot &&
                                spot.ParentOrderItem &&
                                ['FAILED'].indexOf(spot.ParentOrderItem.deliveryStatus) >
                                -1)
                        ) {
                            return false;
                        } else {
                            submitIds.push(spot.id);
                            return true;
                        }
                    });

                    // save off the filtered list of spots
                    localStorage.setItem(this.ACTIVE_SUBMITS, JSON.stringify(submitIds));

                    jobCompletion.resolve(submitIds);
                    // Notify anyone who cares that the active submits have changed
                    this.$rootScope.$broadcast('changeActiveSubmits', filteredSubmits);

                    this.next(uuid);
                });
            } else {
                // save off the filtered list of spots
                localStorage.setItem(this.ACTIVE_SUBMITS, JSON.stringify([]));

                jobCompletion.resolve([]);
                // Notify anyone who cares that the active submits have changed
                this.$rootScope.$broadcast('changeActiveSubmits', []);

                this.next(uuid);
            }
        });

        // If there is no other jobs in there to kick this one off, start on its own
        this.next(0);

        return jobCompletion.promise;
    };

    getActiveUploads() {
        let newJob = this.$q.defer(),
            jobCompletion = this.$q.defer(),
            uuid = this.uuid4.generate();

        this.queue.push({
            uuid: uuid,
            job: newJob,
        });

        newJob.promise.then(() => {
            let activeUploads;

            if (localStorage.getItem(this.ACTIVE_UPLOADS) === null) {
                activeUploads = [];
            } else {
                // Attempt to parse the active submits, otherwise just assume they are nonexistant or empty
                try {
                    activeUploads = JSON.parse(localStorage.getItem(this.ACTIVE_UPLOADS) || '');
                } catch (e) {
                    activeUploads = [];
                }
            }
            jobCompletion.resolve(activeUploads);
            this.next(uuid);
        });

        // If there is no other jobs in there to kick this one off, start on its own
        this.next(0);

        return jobCompletion.promise;
    };

    addActiveSubmit = (newSpotId:any) => {
        let newJob = this.$q.defer(),
            jobCompletion = this.$q.defer(),
            uuid = this.uuid4.generate();

        this.queue.push({
            uuid: uuid,
            job: newJob,
        });

        newJob.promise.then(() => {
            let activeSubmits;

            if (localStorage.getItem(this.ACTIVE_SUBMITS) === null) {
                activeSubmits = [];
            } else {
                // Attempt to parse the active submits, otherwise just assume they are nonexistant or empty
                try {
                    activeSubmits = JSON.parse(localStorage.getItem(this.ACTIVE_SUBMITS) || '');
                } catch (e) {
                    activeSubmits = [];
                }
            }

            activeSubmits.push(newSpotId);
            localStorage.setItem(this.ACTIVE_SUBMITS, JSON.stringify(activeSubmits));

            // Do the normal scrubbing
            return this.LiteDeliveryResourceFactory.getQuiet(
                { spotIds: activeSubmits },
                (spots:any) => {
                    spots = spots.data;

                    // Retrieve IDs for any active submit spot that is not in a terminal status
                    let submitIds:any = [];
                    let filteredSubmits = spots.rows.filter((spot:any) => {
                        if (
                            ['SENT', 'UNSENT', 'REJECTED'].indexOf(
                                spot.status.toUpperCase()
                            ) > -1
                        ) {
                            return false;
                        } else {
                            submitIds.push(spot.id);
                            return true;
                        }
                    });

                    // save off the filtered list of spots
                    localStorage.setItem(this.ACTIVE_SUBMITS, JSON.stringify(submitIds));
                    jobCompletion.resolve(submitIds);
                    // Notify anyone who cares that the active submits have changed
                    this.$rootScope.$broadcast('changeActiveSubmits', filteredSubmits);

                    this.next(uuid);
                }
            );
        });

        // If there is no other jobs in there to kick this one off, start on its own
        this.next(0);

        return jobCompletion.promise;
    };

    addActiveUpload(newUpload:any) {
        let newJob = this.$q.defer(),
            jobCompletion = this.$q.defer(),
            uuid = this.uuid4.generate();

        this.queue.push({
            uuid: uuid,
            job: newJob,
        });

        newJob.promise.then(() => {
            let activeUploads;

            if (localStorage.getItem(this.ACTIVE_UPLOADS) === null) {
                activeUploads = [];
            } else {
                // Attempt to parse the active submits, otherwise just assume they are nonexistant or empty
                try {
                    activeUploads = JSON.parse(localStorage.getItem(this.ACTIVE_UPLOADS) || '');
                } catch (e) {
                    activeUploads = [];
                }
            }
            activeUploads.push(newUpload);
            localStorage.setItem(this.ACTIVE_UPLOADS, JSON.stringify(activeUploads));
            jobCompletion.resolve(activeUploads);

            this.next(uuid);
        });

        // If there is no other jobs in there to kick this one off, start on its own
        this.next(0);

        return jobCompletion.promise;
    };

    removeActiveUpload(uploadTrackingId:any) {
        let newJob = this.$q.defer(),
            jobCompletion = this.$q.defer(),
            uuid = this.uuid4.generate();

        this.queue.push({
            uuid: uuid,
            job: newJob,
        });

        newJob.promise.then(() => {
            let activeUploads;

            if (localStorage.getItem(this.ACTIVE_UPLOADS) === null) {
                activeUploads = [];
            } else {
                // Attempt to parse the active submits, otherwise just assume they are nonexistant or empty
                try {
                    activeUploads = JSON.parse(localStorage.getItem(this.ACTIVE_UPLOADS) || '');
                } catch (e) {
                    activeUploads = [];
                }
            }

            // Find the upload for that spot and remove it from activeUploads
            for (let t = 0; t < activeUploads.length; t++) {
                if (
                    activeUploads[t].trackingId === uploadTrackingId ||
                    activeUploads[t].spotId === uploadTrackingId
                ) {
                    activeUploads.splice(t, 1);
                    break;
                }
            }

            localStorage.setItem(this.ACTIVE_UPLOADS, JSON.stringify(activeUploads));
            jobCompletion.resolve(activeUploads);

            this.next(uuid);
        });

        // If there is no other jobs in there to kick this one off, start on its own
        this.next(0);

        return jobCompletion.promise;
    };

    updateActiveUpload(uploadTrackingId:any, newActiveUploadObj:any) {
        let newJob = this.$q.defer(),
            jobCompletion = this.$q.defer(),
            uuid = this.uuid4.generate();

        this.queue.push({
            uuid: uuid,
            job: newJob,
        });

        newJob.promise.then(() => {
            let activeUploads;

            if (localStorage.getItem(this.ACTIVE_UPLOADS) === null) {
                activeUploads = [];
            } else {
                // Attempt to parse the active submits, otherwise just assume they are nonexistant or empty
                try {
                    activeUploads = JSON.parse(localStorage.getItem(this.ACTIVE_UPLOADS) || '');
                } catch (e) {
                    activeUploads = [];
                }
            }

            // Find the upload for that spot and remove it from activeUploads
            for (let t = 0; t < activeUploads.length; t++) {
                if (
                    activeUploads[t].trackingId === uploadTrackingId ||
                    activeUploads[t].spotId === uploadTrackingId
                ) {
                    for (let prop in newActiveUploadObj) {
                        if (newActiveUploadObj.hasOwnProperty(prop)) {
                            activeUploads[t][prop] = newActiveUploadObj[prop];
                        }
                    }
                    break;
                }
            }

            localStorage.setItem(this.ACTIVE_UPLOADS, JSON.stringify(activeUploads));
            jobCompletion.resolve(activeUploads);

            this.next(uuid);
        });

        // If there is no other jobs in there to kick this one off, start on its own
        this.next(0);

        return jobCompletion.promise;
    };

    cleanOut() {
        localStorage.removeItem(this.ACTIVE_SUBMITS);
        localStorage.removeItem(this.ACTIVE_UPLOADS);
    };

    $onInit() {
        this.next();
    }
}

angular.module('comcast.common.communication').service('submitManager', SubmitManager);
