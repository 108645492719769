angular.module('comcast.common.communication').factory('socket', ['$http', '$q', '$rootScope', 'EndPointService', 'loginService', 'submitManager', 'NotificationService', function ($http, $q, $rootScope, EndPointService, loginService, submitManager, NotificationService) {
  // Socket.io attaches itself to the window object so we can access it here. We'll wrap it in a factory though,
  // so we can inject it wherever we need as well as let it update our viewModels dynamically.
  var self = this;
  var socket;
  var sessionData;
  var connected = $q.defer();
  $http({
    method: 'GET',
    url: '/socketConfig'
  }).then(function successCallback(response) {
    socket = io.connect(response.data + EndPointService.socketEndpoint, {
      transports: ['websocket']
    });
    connected.resolve(response.data);
  }, function errorCallback(response) {
    // Unable to connect to node to get the socket configurations
    connected.reject(response);
  }); // Handle various automated listening events

  function autoHandle(message) {
    sessionData = loginService.getSessionData();

    if (message.error !== undefined) {
      NotificationService.showNotificationToast(message.error.message, message.error.name);
      return;
    }

    switch (message.messageType) {
      case 'liteSubmit':
        handleLiteSubmits(message.spot);
        break;

      case 'spotSubmit':
        handleSubmits(message.spot);
        break;

      case 'userError':
        handleError(message.error);
        break;

      default:
        // Do Nothing
        break;
    }
  }

  function handleLiteSubmits(newSpot) {
    submitManager.removeActiveUpload(newSpot.trackingId).then(function removed() {
      // Add the new spot to the activeSubmit queue
      if (sessionData.liteAccount && newSpot.orderGroups && newSpot.orderGroups[0].orderGroupSpots && newSpot.orderGroups[0].orderGroupSpots[0].spot) {
        submitManager.addActiveSubmit(newSpot.orderGroups[0].orderGroupSpots[0].spot.id);
      } else {
        submitManager.addActiveSubmit(newSpot.id);
      }
    });
    submitManager.getActiveSubmits();
  }

  function handleSubmits(spot) {
    // Clean up the entry in the activeUploads session object
    submitManager.removeActiveUpload(spot.id).then(function () {
      $rootScope.$broadcast('submitUploadComplete', spot);
    });
  }

  function handleError(error) {
    NotificationService.showNotificationToast('Error occurred', error);
  }

  self.on = function (eventName, callback) {
    connected.promise.then(function success() {
      socket.on(eventName, function () {
        var args = arguments;
        $rootScope.$apply(function () {
          callback.apply(socket, args);
        });
      });
    });
  };

  self.emit = function (eventName, data, callback) {
    connected.promise.then(function success() {
      socket.emit(eventName, data, function () {
        var args = arguments;
        $rootScope.$apply(function () {
          if (callback) {
            callback.apply(socket, args);
          }
        });
      });
    });
  };

  self.userLevelListen = function (userId) {
    if (socket) {
      socket.removeListener('UserInteraction-' + userId, self.userLevelListen);
      socket.on('UserInteraction-' + userId, function () {
        var message = arguments;
        $rootScope.$apply(function () {
          autoHandle.apply(socket, message);
        });
      });
    }
  };

  self.getStickyUrl = function () {
    return connected.promise;
  }; // This code is specifically for when a user refreshes in the app, so that we keep listening


  connected.promise.then(function success() {
    // Initialization code (must be after the self.method declarations, because they are variables and are flag-poled at the same level
    var testForUser = loginService.getSessionData();

    if (testForUser && testForUser.id) {
      socket.removeListener('UserInteraction-' + testForUser.id, self.userLevelListen);
      self.userLevelListen(testForUser.id);
    }
  });
  return self;
}]);