import { Injectable } from '@angular/core';

@Injectable()
export class UserAuthenticationFactory {
    constructor() { }

    static build(data:any) {
        return {
            isFilteredUser: false,
            franchiseCustomer: false,
            noDashboards: false,
            sessionKey: data.sessionKey,
            emailAddress: data.emailAddress,
            enabled: data.enabled,
            firstName: data.firstName,
            lastName: data.lastName,
            id: data.id,
            accountType: data.accountType,
            username: data.username,
            userRoles: data.userRoles,
            adminFlag: data.adminFlag,
            liteAccount: data.liteAccount,
            accountId: data.accountId,
            durations: data.durationList.durations,
            userPreferences: data.userPreferences,
            accountName: data.accountName,
            destinationType: data.destinationType,
            ignoreDateRestriction: data.ignoreDateRestriction,
            offsiteObjectStorageEnabled: data.securityAccount && data.securityAccount.offsiteObjectStorageEnabled
                ? data.securityAccount.offsiteObjectStorageEnabled
                : false,
            allowOrderOptimization: data.allowOrderOptimization,
            securityAccount: data.securityAccount,
            account: data.securityAccount,
            defaultDestinationListId: data.defaultDestinationListId,
            permissionContexts: data.PermissionContexts,
            asperaAccountDetails: data.asperaAccountDetails,
        };
    };
}
